import React from 'react';
import './SliderCarousel.css';
import Logo from '../images/tn1.png'

const SliderCarousel = () => {

  return (
    <div className="white-space-preline slider-container">
      
      <div className="white-space-preline slider">
          <div className='sliderTopper'>
            <div className='sliderTopperLeft'>
              <h2>WORKING FOR COMMUNITIES AND MAKING COMMUNITIES WORK.</h2>
            </div>

            <div className='sliderTopperRight'>
              <img src={Logo} alt="" />

              <h2>The Newberry Institute</h2>
            </div>
          </div>

          <div className='sliderBottomer'>
            <div>
              <h1 style={{color: '#f5ffff'}}>Mission</h1>
            <p >
              The Newberry Institute is dedicated to strengthening rural communities by preserving our nation’s history, promoting self-reliance, and fostering economic growth. Through strategic partnerships, we revitalize historic downtowns to support small businesses, expand housing opportunities, and enhance civic engagement.
            <br></br>
              We work to increase access to healthy food, encourage local leadership, and collaborate with government agencies to ensure responsible development. Guided by traditional values of faith, hard work, and community, we are committed to building stronger, more self-sufficient rural communities.
            </p>
            </div>
          </div>
      </div>

    </div>
  );
};

export default SliderCarousel;
