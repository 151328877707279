import React, { useEffect, useState } from 'react';
import './RestOfPage.css'
import { Link } from 'react-router-dom';
import { Button } from '@mui/material'
import BaseUrl from '../../BaseUrl';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import Banner from '../Banner/Banner';
import FirstResource from '../images/Resources/Acti-Vote.png'
import USDA from '../images/usda&fs.png'
import SecondResource from '../images/Resources/Out-reach Circle1.png'

function formatDate(dateString) {
  const options = {
    weekday: 'long',
    day: 'numeric',
    year: 'numeric',
    month: 'long',
    hour: '2-digit',
    minute: '2-digit'
  };
  const formattedDate = new Date(dateString).toLocaleDateString('en-US', options);
  return formattedDate;
}

function formatDateString(dateString) {
  const options = {
    // weekday: 'long',
    day: 'numeric',
    year: 'numeric',
    month: 'long'
  };
  const formattedDate = new Date(dateString).toLocaleDateString('en-US', options);
  return formattedDate;
}

function RestOfPage({ref}) {
  const [eventList, setEventList] = useState([]);

  const [latestEventList, setLatestEventList] = useState([]);

  const [researchList, setResearchList] = useState([]);

  const [podcastList, setPodcastList] = useState([]);

  const Navigate = useNavigate()

  useEffect(() => {
    axios
    .get(`${BaseUrl}/api/get-all-event`)
    .then((response) => {
      const events = response.data.events;

      // Sort events by date in descending order
      const sortedEvent = events.sort((a, b) => new Date(a.date) - new Date(b.date));


      // Format the events
      const formattedEvents = sortedEvent.map(event => {
        return {
          id: event.id,
          title: event.title,
          start: event.date, // Assuming start is a date string in ISO format
          end: event.date,   // Assuming end is a date string in ISO format
          tooltip: event.title,
          desc: event.description
        };
      });


      setEventList(formattedEvents);
    })
    .catch((error) => {
      console.error('Error fetching research records:', error);
    });



      axios
      .get(`${BaseUrl}/api/get-all-event`)
      .then((response) => {
        const events = response.data.events;

        if (events.length > 0) {
          events.sort((a, b) => new Date(a.date) - new Date(b.date));
          const latestEvent = events[0];

          const formattedEvent = {
            id: latestEvent.id,
            title: latestEvent.title,
            start: latestEvent.date,
            end: latestEvent.date,
            tooltip: latestEvent.title,
            desc: latestEvent.description
          };


          

          setLatestEventList([formattedEvent]);
        } else {
          setLatestEventList([]);
        }
      })
      .catch((error) => {
        console.error('Error fetching research records:', error);
      });


      axios
      .get(`${BaseUrl}/api/get-all-story`)
      .then((response) => {
        const formattedStories = response.data.stories.map(story => {
          return {
            ...story
          };
        });
        setResearchList(formattedStories);
      })
      .catch((error) => {
        console.error('Error fetching research records:', error);
      });


      axios
      .get(`${BaseUrl}/api/get-all-podcast`)
      .then((response) => {
        const formattedPodcasts = response.data.podcasts.map(podcast => {
          return {
            ...podcast
          };
        });
        setPodcastList(formattedPodcasts);
      })
      .catch((error) => {
        console.error('Error fetching research records:', error);
      });
      
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);





  return (
    <div className='landingPageRestOfPage'>

      <div className='ameenah'>
        <div></div>
        <div>
            <h1> Value Statement</h1>
        </div>
        <div></div>
      </div>

      <div className='two'>
        <div>
            <div>
                <p>
              The Newberry Institute is committed to strengthening communities by preserving our history, promoting self-reliance, and fostering responsible growth. We believe in the power of hard work, faith, and local leadership to create thriving, self-sustaining communities.
            </p>
            <p>
              Through education, economic development, and civic engagement, we empower individuals to take an active role in shaping their future. Rooted in traditional American values, we prioritize strong families, personal responsibility, and partnerships that uphold the integrity and prosperity of our towns and rural communities.
            </p>
            </div>
        </div>
      </div>


      <div className='objectivity'>
        <div className='objectiveLeft'>
          <h2>Programs</h2>

          <ul>
            <li>Community based civic education</li> 
            <li>Rosa`s Food Pantry</li> 
            <li>Cultural heritage awarness</li> 
            <li>Interfaith cooperation through dialogue </li> 
            <li>Forest Service community partnership initiative</li>
          </ul>
        </div>
        <div className='objectiveRight'>
          <h2>Objectives</h2>
          <ul >
            <li>Increase citizenship responsbility</li>
            <li>Promote good health</li> 
            <li>Educate individuals about their uniqueness</li> 
            <li>Promote religious tolerance</li>
            <li>Increase community awarness about Forestry</li>
          </ul>

        </div>
      </div>

      <div className='one'>
        <div>
          <h2 className=''>USDA Forest Service</h2>
        </div>
      </div>

      <div className='usda ameenah'>
        <div></div>
        <div>
          <Link to="/usda-forest-service">
              <img src={USDA} className='usdaImage'  alt="" />
          </Link>
        </div>
        <div></div>
      </div>

      <div className='ameenah-intro-texth'>
        <p>Empowering future forest professionals with education, resources, and hands-on experience.</p>
      </div>

      {/* Start  of Ameenahs faith section */}
      <Banner />
      {/* End of Ameenahs faith section */}

      <div className='ameenah'>
        <div></div>
        <div>
            <h1>Attend an event</h1>
        </div>
        <div></div>
      </div>
    

      <div className='four'>
        <div>
          <div className='actionNetwork'>
            <h2 style={{color: '#f5ffff'}}>Join our action network activities</h2>

            <Button onClick={()=>{
              Navigate('/action-network')
            }}>Visit</Button>
          </div>

          <div>
            {latestEventList && latestEventList.map((latestEventList, index)=>(
              <div key={index} onClick={()=>{
                Navigate(`/event/${latestEventList.id}?slug=${encodeURIComponent(latestEventList.title).replace(/%20/g, '-')}`)
              }}>
                <div>
                  <h4 style={{color: '#f5ffff'}}>{formatDate(latestEventList.start)}</h4>
                </div>
                <div>
                  <p style={{color: '#f5ffff'}}>
                    {latestEventList.desc.length > 50
                        ? latestEventList.desc.slice(0, 50) + '...'
                        : latestEventList.desc}
                  </p>
                  <h4 style={{color: '#f5ffff'}}>
                    {latestEventList.title.length > 100
                        ? latestEventList.title.slice(0, 100) + '...'
                        : latestEventList.title}
                  </h4>
                  <p>{formatDate(latestEventList.start)}</p>
                </div>
              </div>
            ))}
            

            <div>
            {eventList && eventList.slice(1, 4).map((eventItem, index) => (
                <div key={index} onClick={() => {
                  Navigate(`/event/${eventItem.id}?slug=${encodeURIComponent(eventItem.title).replace(/%20/g, '-')}`)
                }}>
                  <div>
                    <h4 style={{color: '#f5ffff'}}>{formatDate(eventItem.start)}</h4>
                  </div>
                  <div>
                    <p>
                      {eventItem.desc.length > 30
                        ? eventItem.desc.slice(0, 30) + '...'
                        : eventItem.desc}
                    </p>
                    <h4 style={{color: '#f5ffff'}}>
                      {eventItem.title.length > 100
                        ? eventItem.title.slice(0, 100) + '...'
                        : eventItem.title}
                    </h4>
                    <p>{formatDate(eventItem.start)}</p>
                  </div>
                </div>
            ))}
              
            </div>
          </div>

          {eventList.length > 3 && <Button className='eventButtonClass' onClick={()=>{
            Navigate('/events')
          }}>See all event</Button>}
          
        </div>
      </div>



      <div className='ameenah'>
        <div></div>
        <div>
            <h1>Resources</h1>
        </div>
        <div></div>
      </div>


      <div className='seven'>
        <div className='left'>
            <img src={FirstResource} alt="" />

            <p  onClick={()=>{
              window.open('https://www.activote.net/', '_blank');
            }}>https://www.activote.net</p>

            <p>Are you ready to make a difference in your community and be a more informed voter? Look no further than Activote the mobile application that's revolutionizing civic engagement and voter participation.</p>
        </div>

        <div className='right'>
          <img src={SecondResource} alt="" />

          <p onClick={()=>{
              window.open('https://outreachcircle.com/', '_blank');
          }}>https://outreachcircle.com</p>

          <p>Ready to make a positive impact in our community? Join the Newberry Institute's Outreach Circle and be a part of the change we all want to see. Together we're stronger.</p>
        </div>
      </div>



      <div className='six'>
        <div>
            <h3>Featured Articles from around the Globe.</h3>
            <div>
              <p></p>
              <p onClick={()=>{
                Navigate(`/stories`)
              }}></p>
            </div>

            <div>
              {researchList && researchList.map((object, index) => (
                <div key={index} onClick={()=>{
                  Navigate(`/story/${object.id}?slug=${encodeURIComponent(object.subtitle).replace(/%20/g, '-')}`)
                }}>
                  <img src={`${BaseUrl}/images/${object.image}`} alt={object.title} />
                  <p>
                    {object.title.length > 100
                        ? object.title.slice(0, 100) + '...'
                        : object.title}
                      <p>
                    {formatDateString(object.date)}
                  </p>
                  </p>
                </div>
              ))}
            </div>


            <Button onClick={()=>{
                Navigate(`/stories`)
              }}>See all</Button>
        </div>

        <div>
          <h3>Podcast & Featured Youtube Videos.</h3>
          <div>
            <p></p>
            <p onClick={()=>{
                Navigate(`/podcasts`)
              }}></p>
          </div>

          <div>
            {podcastList && podcastList.map((object, index) => (
              <div key={index} onClick={()=>{
                Navigate(`/podcast/${object.id}?slug=${encodeURIComponent(object.title).replace(/%20/g, '-')}`)
              }}>
                <img src={`${BaseUrl}/images/${object.image}`} alt={object.title} />
                <p>
                  {object.title.length > 100
                        ? object.title.slice(0, 100) + '...'
                        : object.title}
                </p>
              </div>
            ))}
          </div>

          <Button onClick={()=>{
                Navigate(`/podcasts`)
              }}>See all</Button>
        </div>
      </div>

    </div>
  )
}

export default RestOfPage